import {
    createSelector,
} from '@reduxjs/toolkit';

import {
    selectIsMobile,
    selectIsHybrid,
    selectAllowLogin,
} from './clientStateSelectors';

export const selectAcceptOddsFlag = ({ user }) => user.acceptOddsChange;
export const selectConfirmCashout = ({ user }) => user.confirmCashout;
export const selectCountryIso = ({ user }) => user.countryIso?.toUpperCase();
export const selectCountryState = ({ user }) => user.countryState;
export const selectCurrencyIso = ({ user }) => user.currencyIso?.toUpperCase();
export const selectCustomerId = ({ user }) => user.customerId;
export const selectFavouriteSports = ({ user }) => user.favouriteSports;
export const selectHasKambiBets = ({ user }) => user.hasKambiBets;
export const selectInstructionCards = ({ user }) => user.instructionCards;
export const selectIsBogRestricted = ({ user }) => user.isBogRestricted;
export const selectIsEachwayAllowed = ({ user }) => user.isEachwayAllowed;
export const selectIsFirstLogin = ({ user }) => user.isFirstLogin;
export const selectIsGeoCheckFailure = ({ user }) => user.geoCheckStatus === 'failed';
export const selectIsGeoCheckInProgress = ({ user }) => ['notStarted', 'inProgress'].includes(user.geoCheckStatus);
export const selectOddsFormat = ({ user }) => user.oddsFormat;
export const selectOpsGroupId = ({ user }) => user.opsGroupId;
export const selectRegulationTypeId = ({ user }) => user.regulationTypeId;
export const selectRestrictedApps = ({ user }) => user.restrictedApps;
export const selectThemeMode = ({ user }) => user.themeMode;
export const selectUserBalance = ({ user }) => user.balance;
export const selectUserMode = ({ user }) => user.userMode;

const selectRegulationTypeData = ({ user }) => user.regulationTypeData;

export const selectIsUserLoggedIn = createSelector(
    selectCustomerId,
    (customerId) => Number.isInteger(customerId)
);

export const selectUserTaxOnReturns = createSelector(
    selectRegulationTypeData,
    ({ tax_on_returns }) => tax_on_returns,
);

export const selectUserBetbuilderRegion = createSelector(
    selectRegulationTypeData,
    ({ betbuilder_region }) => betbuilder_region,
);

export const selectLoginRestricted = createSelector(
    selectRegulationTypeData,
    ({ login_restricted }) => login_restricted === 1,
);

export const selectShouldHideLogin = createSelector(
    selectIsUserLoggedIn,
    selectLoginRestricted,
    selectIsMobile,
    selectIsHybrid,
    selectAllowLogin,
    (isUserLoggedIn, isLoginRestricted, isMobile, isHybrid, allowLogin) => {

        if (allowLogin !== undefined) {
            return !allowLogin;
        }

        return !isUserLoggedIn
            && isLoginRestricted
            && isMobile
            && !isHybrid;
    }
);
