import {
    setState,
    setStateBatch,
} from '~shared/utils/state';
import { post } from '~shared/utils/http';
import { buildSpectateUrl } from './Spectate';
import { createWsInterface } from './Websockets';
import { processClientSettings } from './WrapperIntegration/ClientSettings';
import {
    getBrandId,
    getCountryIso,
    getCountryState,
    getCurrencyIso,
    getCurrentTheme,
    getLanguageCode,
    getProductPackageId,
    getRegulationTypeId,
    getSubBrandId,
    getTimezone,
    getUserDevice,
    getUserMode,
    setAnonymousSpectateUser,
    setCountryIso,
    setCurrencyIso,
    setCurrentTheme,
    setCustomerAcceptOddsFlag,
    setCustomerConfirmCashoutFlag,
    setCustomerId,
    setEachwayAllowed,
    setFavouriteSports,
    setInstructionCards,
    setIsBogRestricted,
    setOddsFormat,
    setOpsGroupId,
    setRegulationTypeData,
} from '~shared/utils/user';
import { getDocumentReferer } from '~shared/utils/win';
import { getMarketingBrandId } from '~spa/SpectateConfig';
import { getClientTimezone } from '~shared/utils/bootstrap-timezone';
import initPolyfills from '~shared/utils/polyfills';
import i18n from '~shared/utils/i18n';
import { loadAliases } from '~shared/utils/uriAliases';
import { kickUser } from '~spa/Utils/WrapperIntegration/Ucf';


const STATE_ENDPOINT_URL = '/load/state';
const KICK_USER_FLAG = 'customer:out_of_sync:kick';
const STATE_INIT_STATE_KEY = 'state:init';

export const initSpectate = async (clientSettings) => {
    await initPolyfills();
    processClientSettings(clientSettings);

    await Promise.all([
        i18n.initialise(),
        loadAliases(),
        loadState(),
    ])
    .then(() => {
        setState(STATE_INIT_STATE_KEY, {isSuccess: true});
    })
    .catch((e) => {
        console.error(e); //eslint-disable-line
        setState(STATE_INIT_STATE_KEY, {isSuccess: false, errorMessage: e});
    });
};

const loadState = async () => {
    const requestParams = {
        currency_code: getCurrencyIso(),
        language: getLanguageCode(),
        sub_brand_id: getSubBrandId(),
        brand_id: getBrandId(),
        marketing_brand_id: getMarketingBrandId(),
        regulation_type_id: getRegulationTypeId(),
        timezone: getTimezone(),
        browsing_country_code: getCountryIso(),
        product_package_id: getProductPackageId(),
        user_mode: getUserMode(),
        spectate_timezone: getClientTimezone(),
        device: getUserDevice(),
        referrer: getDocumentReferer(),
        region: getCountryState(),
        theme_mode: getCurrentTheme(),
    };
    const url = `${buildSpectateUrl(STATE_ENDPOINT_URL)}`;
    const response = await post(url, requestParams);

    let result;
    try {
        result = await response.json();
    } catch (e) {
        result = `http_status: ${response.status}`;
    }

    if (response.ok) {
        const {
            clientState,
            user,
        } = result;

        if (KICK_USER_FLAG in clientState) {
            if (clientState[KICK_USER_FLAG] === true) {
                kickUser();
            }

            // Do not store kick flag in State.
            delete clientState[KICK_USER_FLAG];
        }

        setStateBatch(clientState);

        const {
            acceptOddsChange,
            confirmCashout,
            countryIso,
            currencyIso,
            customerId,
            favouriteSports,
            instructionCards,
            isBogRestricted,
            isEachwayAllowed,
            oddsFormat,
            opsGroupId,
            regulationTypeData,
            themeMode,
        } = user;
        if (acceptOddsChange !== undefined) {
            setCustomerAcceptOddsFlag(acceptOddsChange);
        }
        if (confirmCashout !== undefined) {
            setCustomerConfirmCashoutFlag(confirmCashout);
        }
        if (countryIso !== undefined) {
            setCountryIso(countryIso);
        }
        if (currencyIso !== undefined) {
            setCurrencyIso(currencyIso);
        }
        if (customerId !== undefined) {
            setCustomerId(customerId);
        }
        setInstructionCards(instructionCards);
        setFavouriteSports(favouriteSports);
        setIsBogRestricted(isBogRestricted);
        setEachwayAllowed(isEachwayAllowed);
        setOddsFormat(oddsFormat);
        setOpsGroupId(opsGroupId);
        setRegulationTypeData(regulationTypeData);
        setCurrentTheme(themeMode);
        setAnonymousSpectateUser();

        createWsInterface();

        return Promise.resolve();
    }

    return Promise.reject(result);
};
