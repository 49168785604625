import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';

export const tournamentsAdapter = createEntityAdapter();

const tournamentsSlice = createSlice({
    name: 'tournaments',
    initialState: tournamentsAdapter.getInitialState(),
    reducers: {
        addTournament: tournamentsAdapter.addOne,
        removeTournament: tournamentsAdapter.removeOne,
    },
    extraReducers: (builder) => {
        builder.addCase(
            inplayEventData.fulfilled,
            (state, { payload : { tournament } }) => {
                if (!isEmpty(tournament)) {
                    return tournamentsAdapter.addOne(state, tournament);
                }

                return state;
            },
        );
    },
});

export const { addTournament, removeTournament } = tournamentsSlice.actions;
export default tournamentsSlice.reducer;
