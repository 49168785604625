import { getLanguageIso } from '~shared/utils/user';
import { getPageCorrelationId, getVersionAndBuildNumber } from '~spa/Utils/Spectate';
import { sendEventForClickStream } from '~spa/Utils/WrapperIntegration/Ucf';
import { getClickstreamEnabled } from '~spa/Utils/SiteConfig';
import { getSpectateContext } from '~spa/Router/spectateRouter';

const CLICKSTREAM_ATTRIBUTE_NAME = 'data-spectate-product';
const EVENT_TYPE_CLICK = 'SPEC_SPORT_CLICK';
const EVENT_TYPE_PAGE_VIEW = 'SPEC_SPORT_PAGE_VIEW';

let isClickStreamEnabled = false;

 /**
 * Create clickstream data to be added into the data layer
 *
 * @param {String} eventType the type of the event. eg.: pageView, click
 * @param {object} eventData the custom data of the event
 * @returns {object} object that constains all formatted data
 */
const buildClickstreamData = (eventType, eventData) => {
    const { versionNumber, buildNumber } = getVersionAndBuildNumber();
    const correlationId = getPageCorrelationId();
    const language = getLanguageIso();

    const clickstreamData = {
        event: {
            message: eventType,
            event: eventType,
            innerType: 'Analytics',
            eventNameOverride: eventType,
            eventTypeOverride: ['Log', 'Analytics'],
            data: {
                ...eventData,
                correlationId,
                language,
                buildNumber,
                versionNumber,
            },
        },
        target: 'spec-sport',
    };

    return clickstreamData;
};

/**
 * Takes the event type/data, formats the data, publishes into UC
 * @param {String} eventType the type of the event. eg.: SPEC_SPORT_CLICK, SPEC_SPORT_PAGE_VIEW
 * @param {object} eventData the custom data of the event
 * @returns {void}
 */
const publishClickstreamDataToUC = (eventType, eventData) => {
    if (!isClickStreamEnabled) {
        return;
    }

    const clickstreamData = buildClickstreamData(eventType, eventData);

    // UC publish function
    sendEventForClickStream(clickstreamData);
};

/**
 * Handler for Clickstream data on every route change
 * @param {object} urlContextData the custom data of the event
 * @returns {void}
 */
export const pushToClickstreamByPageViewEvent = (urlContextData = {}) => {
    if (!isClickStreamEnabled || !Object.keys(urlContextData).length) {
        return;
    }

    const eventData = { ...urlContextData };
    delete eventData.originalUrl;
    publishClickstreamDataToUC(EVENT_TYPE_PAGE_VIEW, eventData);
};

/**
 * Handler for CLickstream data on every element click
 * @param {object} event the event data returned from the click event
 * @returns {void}
 */
const pushToClickstreamByClickEvent = ({ target }) => {
    if (!isClickStreamEnabled) {
        return;
    }

    const eventName = target.hasAttribute(CLICKSTREAM_ATTRIBUTE_NAME)
        ? target.getAttribute(CLICKSTREAM_ATTRIBUTE_NAME)
        : undefined;

    if (eventName) {
        const [
            eventCategory = null,
            eventAction = null,
            eventLabel = null,
        ] = eventName.split(';');

        const eventData = {
            eventCategory,
            eventAction,
            eventLabel,
        };

        publishClickstreamDataToUC(EVENT_TYPE_CLICK, eventData);
    }
};

/**
 * Initialize the Clickstream Data Layer
 * @returns {void}
 */
export const initializeClickstream = () => {
    isClickStreamEnabled = getClickstreamEnabled();

    if (isClickStreamEnabled) {
        const context = getSpectateContext();
        document.addEventListener('click', pushToClickstreamByClickEvent, true);
        pushToClickstreamByPageViewEvent(context);
    }
};
