import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { eventDataCleanUp } from '~SpectateStore/actions/eventDataCleanUp';

export const uiMarketsAdapter = createEntityAdapter();

const uiMarketsSlice = createSlice({
    name: 'uiMarkets',
    initialState: uiMarketsAdapter.getInitialState(),
    reducers: {
        addUiMarkets: uiMarketsAdapter.addMany,
        removeUiMarkets: uiMarketsAdapter.removeMany,
    },
    extraReducers: (builder) => {
        builder
        .addCase(
            eventDataCleanUp,
            (state, { payload : eventId }) => {
                Object.values(state.entities).forEach(uiMarket => {
                    if(Number(uiMarket.event_id) === Number(eventId)) {
                        uiMarketsAdapter.removeOne(state, uiMarket.id);
                    }
                });
            },
        )
        .addCase(
            inplayEventData.fulfilled,
            (state, { payload : { uiMarkets } }) => {
                if (!isEmpty(uiMarkets)) {
                    return uiMarketsAdapter.addMany(state, uiMarkets);
                }

                return state;
            },
        );
    },
});

export const { addUiMarkets, removeUiMarkets } = uiMarketsSlice.actions;
export default uiMarketsSlice.reducer;
