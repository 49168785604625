import { createContext } from 'react';
import {
    createDispatchHook,
    createSelectorHook,
} from 'react-redux';

import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { loadBetslipInitialState } from './storage/clientStorage';

import { betslipClientStoreMiddleware } from './middlewares/betslipClientStoreMiddleware';
import { clientStateStoreMiddleware } from './middlewares/clientStateStoreMiddleware';
import { userClientStoreMiddleware } from './middlewares/userClientStoreMiddleware';
import { betslipGtmMiddleware } from './middlewares/betslipGtmMiddleware';
import { subscribeMiddleware } from './middlewares/subscribeMiddleware';
import { optimizelyStoreMiddleware } from './middlewares/optimizelyStoreMiddleware';

import clientStateReducer from './slices/clientStateSlice';
import userReducer from './slices/userSlice';

import availableBetTypesReducer from '~Betslip/slices/availableBetTypesSlice';
import contingenciesReducer from '~Betslip/slices/contingenciesSlice';
import parlayTabReducer from '~Betslip/slices/parlayTabSlice';
import uiReducer from '~Betslip/slices/uiSlice';
import userMultiplesReducer from '~Betslip/slices/userMultiplesSlice';
import userSelectionsReducer from '~Betslip/slices/userSelectionsSlice';
import selectionDetailsReducer from '~Betslip/slices/selectionDetailsSlice';
import frozenSelectionDetailsReducer from '~Betslip/slices/frozenSelectionDetailsSlice';
import betReceiptReducer from '~Betslip/slices/betReceiptSlice';
import freeBetsReducer from '~Betslip/slices/freeBetsSlice';
import babyBetslipReducer from '~Betslip/slices/babyBetslipSlice';
import recommendationsReducer from '~Betslip/slices/recommendationsSlice';
import preferencesReducer from '~Betslip/slices/preferencesSlice';

import sportsReducer from './slices/sportsSlice';
import categoriesReducer from './slices/categoriesSlice';
import tournamentsReducer from './slices/tournamentsSlice';
import eventsReducer from './slices/eventsSlice';
import marketsEventsReducer from './slices/marketsEventsSlice';
import marketsReducer from './slices/marketsSlice';
import uiMarketsReducer from './slices/uiMarketsSlice';
import selectionsReducer from './slices/selectionsSlice';
import scoreboardsReducer from './slices/scoreboardsSlice';

import optimizelyReducer from './slices/optimizelySlice';

const SpectateStoreContext = createContext(null);

const spectateStore = configureStore({
    preloadedState: {
        betslip: loadBetslipInitialState(),
    },
    reducer: {
        clientState: clientStateReducer,
        user: userReducer,
        betslip: combineReducers({
            availableBetTypes: availableBetTypesReducer,
            contingencies: contingenciesReducer,
            parlayTab: parlayTabReducer,
            ui: uiReducer,
            userMultiples: userMultiplesReducer,
            userSelections: userSelectionsReducer,
            selectionDetails: selectionDetailsReducer,
            frozenSelectionDetails: frozenSelectionDetailsReducer,
            betReceipt: betReceiptReducer,
            freeBets: freeBetsReducer,
            babyBetslip: babyBetslipReducer,
            recommendations: recommendationsReducer,
            preferences: preferencesReducer,
        }),
        sports: sportsReducer,
        categories: categoriesReducer,
        tournaments: tournamentsReducer,
        events: eventsReducer,
        markets: marketsReducer,
        marketsEvents: marketsEventsReducer,
        uiMarkets: uiMarketsReducer,
        selections: selectionsReducer,
        scoreboards: scoreboardsReducer,
        optimizely: optimizelyReducer,
    },
    devTools: {
        name: 'Spectate Store',
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(betslipClientStoreMiddleware)
        .concat(betslipGtmMiddleware)
        .concat(clientStateStoreMiddleware)
        .concat(userClientStoreMiddleware)
        .concat(subscribeMiddleware)
        .concat(optimizelyStoreMiddleware),
});

const ssDispatch = spectateStore.dispatch;
const ssGetState = spectateStore.getState;

const useSsDispatch = createDispatchHook(SpectateStoreContext);
const useSsSelector = createSelectorHook(SpectateStoreContext);

// mock betslipStore to avoid changing massive amount of code.
const betslipStore = {
    getState: ssGetState,
    dispatch: ssDispatch,
};

export {
    SpectateStoreContext,
    spectateStore,
    ssDispatch,
    ssGetState,
    useSsDispatch,
    useSsSelector,
    betslipStore,
};
