import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { inplayEventUpdate } from '~SpectateStore/actions/inplayEventUpdate';
import { eventDataCleanUp } from '~SpectateStore/actions/eventDataCleanUp';

export const marketsEventsAdapter = createEntityAdapter();

const marketsEventsSlice = createSlice({
    name: 'marketsEvents',
    initialState: marketsEventsAdapter.getInitialState(),
    reducers: {
        addMarketsEvents: marketsEventsAdapter.addMany,
        removeMarketsEvents: marketsEventsAdapter.removeMany,
    },
    extraReducers: (builder) => {
        builder
        .addCase(
            eventDataCleanUp,
            (state, { payload : eventId }) => {
                Object.values(state.entities).forEach(marketEvent => {
                    if(Number(marketEvent.event_id) === Number(eventId)) {
                        marketsEventsAdapter.removeOne(state, marketEvent.id);
                    }
                });
            },
        )
        .addCase(
            inplayEventData.fulfilled,
            (state, { payload : { markets_events } }) => {
                if (!isEmpty(markets_events)) {
                    return marketsEventsAdapter.addMany(state, markets_events);
                }

                return state;
            },
        )
        .addCase(
            inplayEventUpdate,
            (state, { payload : { marketsEvents } }) => {
                if (marketsEvents.length >= 1) {
                    return marketsEventsAdapter.upsertMany(state, marketsEvents);
                }

                return state;
            },
        );
    },
});

export const { addMarketsEvents, removeMarketsEvents } = marketsEventsSlice.actions;
export default marketsEventsSlice.reducer;
