import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { inplayEventUpdate } from '~SpectateStore/actions/inplayEventUpdate';
import { eventDataCleanUp } from '~SpectateStore/actions/eventDataCleanUp';

export const selectionsAdapter = createEntityAdapter();

const selectionsSlice = createSlice({
    name: 'selections',
    initialState: selectionsAdapter.getInitialState(),
    reducers: {
        addSelections: selectionsAdapter.addMany,
        removeSelections: selectionsAdapter.removeMany,
    },
    extraReducers: (builder) => {
        builder
        .addCase(
            eventDataCleanUp,
            (state, { payload : eventId }) => {
                Object.values(state.entities).forEach(selection => {
                    if(Number(selection.event_id) === Number(eventId)) {
                        selectionsAdapter.removeOne(state, selection.id);
                    }
                });
            },
        )
        .addCase(
            inplayEventData.fulfilled,
            (state, { payload : { selections } }) => {
                if (!isEmpty(selections)) {
                    return selectionsAdapter.addMany(state, selections);
                }

                return state;
            },
        )
        .addCase(
            inplayEventUpdate,
            (state, { payload : { selections, selectionsToBeRemoved } }) => {
                if(selections.length >= 1 || selectionsToBeRemoved.length >= 1) {
                    selectionsAdapter.removeMany(state, selectionsToBeRemoved);

                    return selectionsAdapter.upsertMany(state, selections);
                }

                return state;
            },
        );
    },
});

export const { addSelections, removeSelections } = selectionsSlice.actions;
export default selectionsSlice.reducer;
