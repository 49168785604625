import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { inplayEventUpdate } from '~SpectateStore/actions/inplayEventUpdate';

export const marketsAdapter = createEntityAdapter();

const marketsSlice = createSlice({
    name: 'markets',
    initialState: marketsAdapter.getInitialState(),
    reducers: {
        addMarkets: marketsAdapter.addMany,
        removeMarkets: marketsAdapter.removeMany,
    },
    extraReducers: (builder) => {
        builder
        .addCase(
            inplayEventData.fulfilled,
            (state, { payload : { markets } }) => {
                if (!isEmpty(markets)) {
                    return marketsAdapter.addMany(state, markets);
                }

                return state;
            },
        )
        .addCase(
            inplayEventUpdate,
            (state, { payload : { markets } }) => {
                // Only updates markets slice if new market.
                // MarketsEvents slice contains things that change via WS, not markets
                markets.forEach(market => {
                    if (!state.entities[market.id]) {
                        marketsAdapter.upsertOne(state, market);
                    }
                });
            },
        );
    },
});

export const { addMarkets, removeMarkets } = marketsSlice.actions;
export default marketsSlice.reducer;
