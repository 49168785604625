import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';

export const sportsAdapter = createEntityAdapter();

const sports = createSlice({
    name: 'sports',
    initialState: sportsAdapter.getInitialState(),
    reducers: {
        addSport: sportsAdapter.addOne,
        removeSport: sportsAdapter.removeOne,
    },
    extraReducers: (builder) => {
        builder.addCase(
            inplayEventData.fulfilled,
            (state, { payload : { sport } }) => {
                if (!isEmpty(sport)) {
                    return sportsAdapter.addOne(state, sport);
                }

                return state;
            },
        );
    },
});

export const { addSport, removeSport } = sports.actions;

export default sports.reducer;
