import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';

export const categoriesAdapter = createEntityAdapter();

const categoriesSlice = createSlice({
    name: 'categories',
    initialState: categoriesAdapter.getInitialState(),
    reducers: {
        addCategory: categoriesAdapter.addOne,
        removeCategory: categoriesAdapter.removeOne,
    },
    extraReducers: (builder) => {
        builder.addCase(
            inplayEventData.fulfilled,
            (state, { payload: { category } }) => {
                if (!isEmpty(category)) {
                    return categoriesAdapter.addOne(state, category);
                }

                return state;
            },
        );
    },
});

export const { addCategory, removeCategory } = categoriesSlice.actions;
export default categoriesSlice.reducer;
