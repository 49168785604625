import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { inplayScoreboardUpdate } from '~SpectateStore/actions/inplayScoreboardUpdate';
import { eventDataCleanUp } from '~SpectateStore/actions/eventDataCleanUp';

export const scoreboardsAdapter = createEntityAdapter();

const scoreboardsSlice = createSlice({
    name: 'scoreboards',
    initialState: scoreboardsAdapter.getInitialState({
        scorecentreProviders: {},
    }),
    reducers: {
        addScoreboard: scoreboardsAdapter.addOne,
        removeScoreboard: scoreboardsAdapter.removeOne,
    },
    extraReducers: (builder) => {
        builder
        .addCase(
            eventDataCleanUp,
            (state, { payload : eventId }) => {
                scoreboardsAdapter.removeOne(state, eventId);
            },
        )
        .addCase(
            inplayEventData.fulfilled,
            (state, { payload : { scoreboard, scorecentre } }) => {
                state.scorecentreProviders = scorecentre ?? {};

                if (!isEmpty(scoreboard)) {
                    return scoreboardsAdapter.addOne(state, scoreboard);
                }

                return state;
            },
        )
        .addCase(
            inplayScoreboardUpdate,
            (state, { payload : { scoreboard } }) => {
                if (scoreboard?.id) {
                    return scoreboardsAdapter.upsertOne(state, scoreboard);
                }

                return state;
            },
        );
    },
});

export const { addScoreboard, removeScoreboard } = scoreboardsSlice.actions;
export default scoreboardsSlice.reducer;
